<template>
  <div class="detail-container">
    <div class="container">
      <div class="box">
        <el-image
          :preview-src-list="[topImg]"
          :src="topImg"
          alt="查看实拍图"
        ></el-image>
        <div class="grab">
          <div class="title">{{ jsonData.vehicleName }}</div>
          <div class="title3">
            <div>机械品牌：</div>
            {{ jsonData.vehicleBrand }}
          </div>
          <div class="title3">
            <div>机械型号：</div>
            {{ jsonData.vehicleModel }}
          </div>
          <div class="title3">
            <div>所在地定位：</div>
            {{ jsonData.vehicleProvince }}{{ jsonData.vehicleCity
            }}{{ jsonData.vehicleCounty }}{{ jsonData.vehicleLocation }}
          </div>
          <div class="title3 price">
            <div>租赁报价：</div>
            <span>￥{{ jsonData.offer }}</span
            >{{jsonData.leaseType == '工作量' ? '元' : `/${jsonData.leaseType}`}}
          </div>
          <div class="title3">
            <el-button size="small">立即沟通</el-button>、
            <el-button size="small" @click="toHire" v-if="userInfo.companyStatus == 2">立即租赁</el-button>
          </div>
        </div>
      </div>
      <div class="nav-box">
        <el-descriptions
          :title="`${jsonData.vehicleName}参数配置`"
          direction="vertical"
          :column="2"
          border
        >
          <el-descriptions-item label="出厂时间">{{
            jsonData.vehicleProductionDate
          }}</el-descriptions-item>
          <el-descriptions-item label="机械类型"
            >{{ jsonData.firstLevelType
            }}{{ jsonData.secondLevelType }}</el-descriptions-item
          >
          <el-descriptions-item label="车辆识别码">{{
            jsonData.vehicleIdentificationCode
          }}</el-descriptions-item>
          <el-descriptions-item label="发动机号码">{{
            jsonData.vehicleEngineNumber
          }}</el-descriptions-item>
          <el-descriptions-item label="机械状态">{{
            jsonData.vehicleStatus == 0 ? '闲置' : '工作中'
          }}</el-descriptions-item>
          <el-descriptions-item label="报价方式">{{
            jsonData.priceTerms == 0
              ? '带司机'
              : jsonData.priceTerms == 1
              ? '包燃油'
              : '包物流'
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="nav-box">
        <div class="title">{{ jsonData.vehicleName }}图片</div>
        <div class="img-box">
          <div class="img-item">
            <div>车辆照片</div>
            <el-image
              :src="item"
              v-for="item in jsonData.vehicleImgs"
              :key="item"
            ></el-image>
          </div>
          <div class="img-item">
            <div>机械铭牌/合格证</div>
            <el-image :src="jsonData.vehicleNameplateImg"></el-image>
          </div>
          <div class="img-item">
            <div>车险保单</div>
            <el-image :src="jsonData.vehicleInsuranceImg"></el-image>
          </div>
          <div class="img-item">
            <div>行驶证</div>
            <el-image :src="jsonData.dlImg"></el-image>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部     -->
    <elFooter></elFooter>
  </div>
</template>

<script>
import elFooter from '@/components/footer'
import { getVehicleInfoById } from '@/api/car'

export default {
  data() {
    return {
      foundDate: '',
      jsonData: {},
      topImg: '',
      userInfo: {}
    }
  },
  components: { elFooter },
  computed: {},
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    getVehicleInfoById(this.$route.query.id).then((res) => {
      this.jsonData = res.data
      if (res.data.vehicleImgs != null) {
        this.topImg = res.data.vehicleImgs[0]
      }
    })
  },
  methods: {
    toHire() {
      this.$router.push({
        path: '/hire',
        query:{
          id:this.$route.query.id,
          leaseUnit:this.jsonData.leaseUnit
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}
.detail-container {
  background-color: #fff;
  .box {
    padding: 20px 0;
    display: flex;
    // align-items: center;
    color: #333;
    .el-image {
      width: 380px;
      height: 240px;
    }
    .grab {
      margin-left: 20px;
      flex: 1;
      font-size: 14px;

      .title {
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
        border-bottom: 2px solid #ddd;
      }
      .title2 {
        color: #666;
        margin: 10px 0 40px 0;
      }
      .title3 {
        display: flex;
        align-items: center;
        margin-top: 10px;
        color: #999999;
        .price {
        }
        span {
          font-size: 20px;
          color: #fc4610;
          font-weight: bold;
        }
        div {
          text-align: left;
        }
        .el-input {
          width: 140px;
        }
        ::v-deep .el-input__suffix {
          display: flex;
          align-items: center;
        }
        .el-button {
          width: 92px;
        }
        .el-button:nth-child(1) {
          color: #3c60bc;
          border: 1px solid #3c60bc;
        }
        .el-button:nth-child(2) {
          background-color: #3c60bc;
          color: #fff;
          border: 1px solid #3c60bc;
        }
      }
    }
  }
  .nav-box {
    margin: 20px 0;
    color: #333;
    .title {
      font-size: 16px;
      font-weight: bold;
      line-height: 30px;
      border-bottom: 2px solid #fcb110;
    }
    ::v-deep .el-descriptions__header {
      font-weight: bold;
      line-height: 30px;
      border-bottom: 2px solid #fcb110;
    }
    .img-box {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      .img-item {
        text-align: center;
        margin-bottom: 20px;
        margin-right: 20px;
        .el-image {
          width: 280px;
          height: 200px;
          margin-right: 10px;
        }
        div {
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
