import request from '@/utils/request.js'
// 查询车辆信息
export function getVehicleInfoByPage(data) {
  return request({
    url: '/vehicle/getVehicleInfoByPage',
    method: 'post',
    data
  })
}
// 企业方查询车辆信息
export function getVehicleInfoByPageOpen(data) {
  return request({
    url: '/open/vehicle/getVehicleInfoByPage',
    method: "post",
    data
  })
}
// 根据车辆id查询车辆信息
export function getVehicleInfoById(id) {
  return request({
    url: '/vehicle/getVehicleInfoById/'+ id,
    method: 'get',
  })
}

// 查询需求列表
export function getNeedList(data) {
  return request({
    url: '/open/require/publish',
    method: 'post',
    data
  })
}
// 查询进行中需求列表
export function getNextList(data) {
  return request({
    url: '/open/require/underway',
    method: 'post',
    data
  })
}

// 需求详情
export function getById(id) {
  return request({
    url: '/open/require/getById/'+ id,
    method: 'get',
  })
}

// 接单车辆查询
export function getVehicleInfoByOrder(data) {
  return request({
    url: '/vehicle/getVehicleInfoByOrder',
    method: 'post',
    data
  })
}

// 创建订单
export function submit(data) {
  return request({
    url: '/cereOrder/submit',
    method: 'post',
    data
  })
}

// 获取订单报价
export function getOffer(data) {
  return request({
    url: '/cereOrder/getOffer',
    method: 'post',
    data
  })
}

// 车辆下单
export function vehicleOrderSubmit(data) {
  return request({
    url: '/cereOrder/vehicleOrderSubmit',
    method: 'post',
    data
  })
}
